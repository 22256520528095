import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
// import { FaArrowRight } from "react-icons/fa/";
// import { FaArrowLeft } from "react-icons/fa/";

const NextPrev = props => {
  const {
    theme,
    next: {
      fields: { prefix: nextPrefix, slug: nextSlug } = {},
      frontmatter: { title: nextTitle } = {},
    } = {},
    prev: {
      fields: { prefix: prevPrefix, slug: prevSlug } = {},
      frontmatter: { title: prevTitle } = {},
    } = {},
  } = props

  return (
    <React.Fragment>
      {nextSlug && (
        <div className="related-posts__item post-card post-card--layout--related">
          <div className="post-card__image">
            <Link to={nextSlug}>
              <Img fluid={props.next.frontmatter.cover.children[0].fluid} />
            </Link>
          </div>
          <div className="post-card__info">
            <div className="post-card__name">
              <Link to={nextSlug}>{nextTitle}</Link>
            </div>
            <div className="post-card__date">{nextPrefix}</div>
          </div>
        </div>
      )}

      {prevSlug && (
        <div className="related-posts__item post-card post-card--layout--related">
          <div className="post-card__image">
            <Link to={prevSlug}>
              <Img fluid={props.prev.frontmatter.cover.children[0].fluid} />
            </Link>
          </div>
          <div className="post-card__info">
            <div className="post-card__name">
              <Link to={prevSlug}>{prevTitle}</Link>
            </div>
            <div className="post-card__date">{prevPrefix}</div>
          </div>
        </div>
      )}

      {/* --- STYLES --- */}
    </React.Fragment>
  )
}

NextPrev.propTypes = {
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired,
}

export default NextPrev
