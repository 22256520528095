import React from 'react'
import PropTypes from 'prop-types'
import 'prismjs/themes/prism-okaidia.css'

// import asyncComponent from "../AsyncComponent";
import Headline from '../Article/Headline'
import Bodytext from '../Article/Bodytext'
import Meta from './Meta'
import Author from './Author'
import Comments from './Comments'
import NextPrev from './NextPrev'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

// const Share = asyncComponent(() =>
//   import("./Share")
//     .then(module => {
//       return module.default;
//     })
//     .catch(error => {})
// );

const Post = props => {
  const {
    post: {
      html,
      fields: { prefix, slug },
      frontmatter: {
        title,
        category,
        author,
        cover: {
          children: [{ fluid }],
        },
      },
    },
    authornote,
    next: nextPost,
    prev: prevPost,
    theme,
  } = props

  return (
    <React.Fragment>
      <div className="block post post--layout--classic">
        <div className="post__header post-header post-header--layout--classic">
          {/*<div className="post-header__categories">
              <Link to={`/category/${category.toLowerCase().replace(' ','-')}`}>
                {category}
              </Link>
            </div>*/}
          <h1 className="post-header__title">{title}</h1>
          <div className="post-header__meta">
            <div className="post-header__meta-item">
              <Link
                to={`/category/${category.toLowerCase().replace(' ', '-')}`}
              >
                {category}
              </Link>
            </div>
            <div className="post-header__meta-item">{prefix}</div>
          </div>
        </div>

        <div className="post__featured">
          <Img fluid={fluid} />
        </div>

        <Bodytext html={html} theme={theme} />

        {/*<div className="post__footer">
            <div className="post__tags-share-links">
                <div className="post__tags tags">
                    <div className="tags__list">
                        <Link to="/">Promotion</Link>
                        <Link to="/">Power Tool</Link>
                        <Link to="/">Wrench</Link>
                        <Link to="/">Electrodes</Link>
                    </div>
                </div>
                <div className="post__share-links share-links">
                    <ul className="share-links__list">
                        <li className="share-links__item share-links__item--type--like"><Link to="/">Like</Link></li>
                        <li className="share-links__item share-links__item--type--tweet"><Link to="/">Tweet</Link></li>
                        <li className="share-links__item share-links__item--type--pin"><Link to="/">Pin It</Link></li>
                        <li className="share-links__item share-links__item--type--counter"><Link to="/">4K</Link></li>
                    </ul>
                </div>
            </div>
            <div className="post-author">
                <div className="post-author__avatar">
                    <Link to="/"><img src="images/avatars/avatar-1.jpg" alt="" /></Link>
                </div>
                <div className="post-author__info">
                    <div className="post-author__name">
                        <Link to="/">Jessica Moore</Link>
                    </div>
                    <div className="post-author__about">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                        suscipit suscipit mi, non tempor nulla finibus eget. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit.
                    </div>
                </div>
            </div>
          </div>*/}
        {/*<Share post={post} theme={theme} /> 
          <Author note={authornote} theme={theme} />*/}

        <section className="post__section">
          <h4 className="post__section-title">Related Posts</h4>
          <div className="related-posts">
            <div className="related-posts__list">
              <NextPrev next={nextPost} prev={prevPost} theme={theme} />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
  authornote: PropTypes.string.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired,
}

export default Post
