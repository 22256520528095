import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Article from '../components/Article'
import Post from '../components/Post'
import StoreContext, { defaultStoreContext } from '../context/StoreContext'
import PageHeader from '../components/Shared/PageHeader'
// import { ThemeContext } from "../layouts";
import Blog from '../components/Blog'
import BlogSidebar from '../components/Blog/BlogSidebar'

const PostTemplate = props => {
  const {
    data: {
      post,
      authornote: { html: authorNote },
      site: {
        siteMetadata: { facebook },
      },
    },
    pageContext: { next, prev },
  } = props

  const breadcrumb = [
    { title: 'Home', url: '/' },
    { title: 'Blog', url: '/blog' },
    {
      title: post.frontmatter.category,
      url: `/category/${post.frontmatter.category
        .toLowerCase()
        .replace(' ', '-')}`,
    },
  ]

  return (
    <React.Fragment>
      <PageHeader breadcrumb={breadcrumb} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <StoreContext.Consumer>
              {theme => (
                <Article theme={theme}>
                  <Post
                    post={post}
                    next={next}
                    prev={prev}
                    authornote={authorNote}
                    facebook={facebook}
                    theme={theme}
                  />
                </Article>
              )}
            </StoreContext.Consumer>
          </div>
          <div className="col-12 col-lg-4">
            <BlogSidebar position="end" />
          </div>
        </div>
      </div>

      <SEO title={post.frontmatter.title} data={post} facebook={facebook} />
    </React.Fragment>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PostTemplate

//eslint-disable-next-line no-undef
export const postQuery = graphql`
  query PostBySlug($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        prefix
      }
      frontmatter {
        title
        author
        category
        cover {
          childImageSharp {
            resize(width: 300) {
              src
            }
          }
          children {
            ... on ImageSharp {
              fluid(maxWidth: 800, maxHeight: 360) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    authornote: markdownRemark(fileAbsolutePath: { regex: "/author/" }) {
      id
      html
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
